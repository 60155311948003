.user-card{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f4;
}

.user-form{
    width: 650px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.two-fields-flex{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
}

/* @media screen and (max-width: 650px) {
    .two-fields-flex {
      flex-direction: column;
    }
  } */


.lable-input-group{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
}

.email-lable-input-group{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.full-input{
    width: 100%;
}

.phone-lable{
    flex: 0 0 70%;
}

.extension-lable{
    flex: 0 0 20%;
}

.accordation{
    margin-top: 2rem;
}

.permission-row{
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-around;
    gap: 3rem;
}

.lable-checkbox{
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
}

.form-header{
    margin-bottom: 2rem;
}

.save-buttom{
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 1.5rem;
}

.password-icon{
    font-size: 1.2rem;
}

.password-icon:hover{
    cursor: pointer;
    color: #6366F1!important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5) as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
  }